(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name home.competicio.controller:CompeticioCtrl
   *
   * @description
   *
   */
  angular
  .module('neo.home.seasons')
  .controller('SeasonsCtrl', SeasonsCtrl);

  function SeasonsCtrl(seasons) {
    var vm = this;
    vm.ctrlName = 'SeasonsCtrl';

    angular.forEach(seasons, function (season) {
      season.iniDate = moment(season.iniDate).format('L');
      season.endDate = moment(season.endDate).format('L');
    });
    vm.listSeasons = seasons;
    vm.query = {
      order: 'name'
    };
    vm.selected = [];
    vm.select = undefined;

  }
}());
